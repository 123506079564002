const data = {
  memorias: [
    {
      title: "1º FASE",
      section: [
        {
          subtitle: "LABORES CULTURALES PREVIAS A LA PLANTACIÓN",
          parrafos: [
            "Es fundamental para el buen desarrollo de los árboles y de las plantas una correcta preparación del terreno, que consistirá en una serie de labores previas para que las raíces de los árboles y arbustos encuentren un medio óptimo para su pronto desarrollo.",
            "Lo primero a tener en cuenta es que la profundidad aprovechable del suelo debe ser como mínimo de 50 cm, y a ser posible se buscarán suelos profundos.",
          ],
        },
        {
          subtitle: "PREPARACIÓN DEL TERRENO",
          parrafos: [
            "Desbroce del suelo y roturación inicial del terreno si hiciera falta, para la adecuada implantación de los diferentes cultivos.",
            "Toda la materia vegetal del desbroce se incorporará en forma de acolchado, a ser posible triturada.",
            "Con todo ello conseguiremos una buena aireación y permeabilidad del terreno que facilitará la instalación del sistema radicular de los diferentes árboles, arbustos y el fácil cultivo de los vegetales y hortalizas. También conseguiremos con ello que no se produzcan encharcamientos, lo que facilitará el acceso a los campos, para la realización de las diferentes labores de plantación y recolección.",
          ],
        },
        {
          subtitle: "PLANTACIÓN",
          parrafos: [
            "En la finca se creará una estructura en tres niveles de árboles frutales y ornamentales, arbustos de bayas y matorral autóctono y una extensa variedad de plantas aromáticas y medicinales. Con todo ello conseguiremos el pretendido equilibrio del autoabastecimiento al crearse de forma natural un clico de aporte de nutrientes, producto de los diferentes elementos vegetales que se irán quedando en el suelo. El mulching que se irá formando ayudará de forma sustentable a reducir cada vez más los aportes hídricos y el laboreo.",
            "El marco de plantación se adaptará a la orografía del terreno y será acorde a la finalidad pretendida de la creación del susodicho bosque de alimentos. Los restos de las podas iniciales y las escardas también se aportarán al suelo triturados como cubierta vegetal, ayudando al control de la expansión de las denominadas “malas hierbas”, y a la erosión del terreno, consiguiendo un sistema de mínimo laboreo. Esto favorecerá el equilibrio entre los diferentes insectos y demás comensales.",
          ],
        },
      ],
    },
    {
      title: "2º FASE",
      section: [
        {
          subtitle: "CONSTRUCCIÓN DE INSTALACIONES",
          parrafos: [
            "En la finca se construirán diferentes instalaciones tales como viviendas, almacén para conservación de materiales y útiles para los diferentes desempeños agrícolas y demás usos que se precisen.",
            "También se proyecta la construcción de una balsa de tierra en la parte más alta de la finca para la acumulación de agua para riego, y que supondrá una reserva importante de esta para los momentos de escasez hídrica y así no tener que emplear ningún tipo de energía.",
            "El agua destinada a la balsa será de los sobrantes del riego y de las canalizaciones para la recogida de agua de lluvia.",
          ],
        },
      ],
    },
    {
      title: "ESTUDIO ECONÓMICO",
      section: [
        {
          subtitle: "",
          parrafos: [
            "Para la puesta en marcha del proyecto en su primera fase se requiere de una inversión de entre 100.000 y 150.000 euros, y estará distribuida de la siguiente forma:",
            "1. Compra de la finca.",
            "2. Compra de maquinaria para las diferentes labores del campo.",
            "3. Limpieza y adecuación de la finca.",
            "4. Acondicionamiento de accesos.",
            "5. Canalización y puesta en marcha del sistema de riego.",
            "6. Preparación del terreno.",
            "7. Compra y plantación de arbolado, arbustivas y aromáticas.",
            "8. Acondicionamiento de los diferentes espacios para la producción de hortalizas.",
            "9. Construcción de un gallinero para la puesta en marcha de las gallinas ponedoras.",
            "10. En esta primera fase se necesitarán dos pequeños almacenes de 50m 2 .",
            "11. Se estima para la puesta en marcha de la primera fase una duración de 1,5 años aproximadamente.",
          ],
        },
      ],
    },
  ],
};
export default data;
