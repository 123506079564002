import cosecha from "./media/cosecha.svg";
import campo from "./media/campo.svg";
import planta1 from "./media/planta1.svg";
import bosque from "./media/bosque.svg";

const data = {
  objetivo_proyecto: {
    title: "OBJETIVO DEL PROYECTO",
    parrafos: [
      "La ubicación apropiada de la finca (para que la naturaleza de forma efectiva y rápida, como ella sabe, empiece a influenciarte a través de sus ritmos naturales) no necesariamente tiene que estar en medio del bosque, pero sí que será necesario un cierto aislamiento, ya que ello ayudará de forma importante a que los ritmos de la naturaleza se puedan percibir más fácilmente y con la suficiente fuerza para que cualquier persona, sin darse cuenta, se vea inmersa y disfrutando de esta nueva sinfonía.",
    ],
  },

  descripcion_proyecto: {
    title: "DESCRIPCION DEL PROYECTO",
    grid: [
      {
        img: cosecha,
        text: [
          "Este proyecto tiene como objetivo realizar el diseño y puesta en marcha de un Bosque de alimentos sobre una finca de un tamaño aproximado de entre 3 a 5 hectáreas.",
        ],
      },
      {
        img: campo,
        text: [
          "La finca objeto de este proyecto estará ubicada a una altura sobre el nivel del mar de entre los 70 a 100 metros. Su favorable clima permitirá, el pronto desarrollo de las diferentes especies de cultivo para un suministro adecuado de fruta, verdura y hortalizas, elementos necesarios para el sustento en esta primera fase.",
        ],
      },
      {
        img: planta1,
        text: [
          `La finca no necesariamente tiene que estar situada en medio del bosque, pero sí que se requiere de un cierto aislamiento, su superficie deberá contener un espacio de bosque mediterráneo aproximadamente del 50%. Con el estudio del comportamiento de la flora y fauna local y su aplicación, facilitaremos que la naturaleza de forma efectiva y rápida, a través de sus ritmos naturales, consiga transmitirnos profundamente esa sensación de ¡estar vivos de verdad!`,
        ],
      },
      {
        img: bosque,
        text: [
          "Al igual que el bálsamo calma una herida, este nuevo ambiente contribuirá a la regeneración y puesta en marcha de la intuición y la armonía, elementos imprescindibles para la integración en el medio rural.",
          '"El bosque de alimentos, servirá de refugio a insectos y aves, y también a las personas"',
        ],
      },
    ],
  },
};
export default data;
