import React, { useState } from "react";
import { slide as Menu } from 'react-burger-menu'
import { useLocation, Link } from "react-router-dom";
import "./header.css";

const routes = [
  { to: "/", text: "Inicio" },
  { to: "/descripcion", text: "Memoria Descriptiva" },
  { to: "/sobre_mi", text: "Sobre mi" },
  { to: "/bibliografia", text: "Bibliografía" },
  { to: "/contact", text: "Contacto" },
];
const elementBgOpaco = { backgroundColor: "#2B2B2B", opacity: 0.6 };
const menu = { backgroundColor: "#2B2B2B", opacity: 1 };
const Header = (props) => {
  //  const burguer=useState
  let resumeData = props.resumeData;
  const location = useLocation();
  const [burgerMenu, setBurgerMenu] = useState({
    show: false,
  });
  const changeVisibilityMenu = () => {
    console.log("burgerMenu.show", burgerMenu.show);
    setBurgerMenu({
      ...burgerMenu,
      show: burgerMenu.show ? false : true,
    });
  };

  console.log("location", { location });
  return (
    <>
      <nav
        style={menu}
        className={location.pathname !== "/" ? "opaque" : ""}
        id="nav-wrap"
      >
        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
          Show navigation
        </a>
        <a className="mobile-btn" href="#" title="Hide navigation">
          Hide navigation
        </a>

        <ul
         /*  style={
            !burgerMenu.show
              ? { display: "none" }
              : {
                  display: "inline",
                  backgroundColor: "#2B2B2B",
                  paddingBottom: "50px",
                  paddingTop: "30px",
                }
          } */
          id="nav"
          className="nav"
        >
          {routes.map((route) => (
            <li className={location.pathname === route.to ? "current" : ""}>
              <Link onClick={() => changeVisibilityMenu()} to={route.to}>
                {route.text}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      {location.pathname !== "/" ? null : (
        <header className="header" id="home">
          <div className="row banner">
            <div
              style={{ ...elementBgOpaco, padding: "15px" }}
              className="banner-text"
            >
              <h1 className="responsive-headline">{resumeData.header.main}</h1>
              <h3 style={{ color: "#fff", fontFamily: "sans-serif " }}>
                {resumeData.header.submain}
              </h3>
              <hr />
              <ul className="social">
                {resumeData.socialLinks &&
                  resumeData.socialLinks.map((item) => {
                    return (
                      <li key={item.name}>
                        <a href={item.url} target="_blank">
                          <i className={item.className}></i>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>

          <p className="scrolldown">
            <a className="smoothscroll" href="#about">
              <i className="icon-down-circle"></i>
            </a>
          </p>
        </header>
      )}
    </>
  );
};
export default Header;

/* 
<li>
            <a className="smoothscroll" href="#resume">
              Resume
            </a>
          </li> */
