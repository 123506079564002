import React, { Component } from "react";
import "./home.css";
import data from "./info";

const Home = (props) => {
  let resumeData = props.resumeData;
  return (
    <>
      <div className="home bloque">
        <div className="info cursiva">
          <p style={{fontSize:"22px",   lineHeight: "150%"}}>
          ¡¡¡El proyecto agroecológico{" "}
            <span className="bold">'Volver a Vivir'</span> es la respuesta
            directa a la necesidad imperiosa de{" "}
            <span className="bold">vivir plenamente</span>!!!
          </p>
        </div>
      </div>
      <div className="home bcg">
        <h1>
          <span> {data.descripcion_proyecto.title}</span>
        </h1>
        <div className="grid-padre">
          {data.descripcion_proyecto.grid.map((info, i) => {
            if (i % 2 === 0) {
              return (
                <div className="grid-common grid">
                  <img src={info.img}></img>
                  <div>
                    {info.text.map((e) => (
                      <p>{e}</p>
                    ))}
                  </div>
                </div>
              );
            } else {
              return (
                <div className="grid-common grid2">
                  <div>
                    {info.text.map((e) => (
                      <div>{e}</div>
                    ))}
                  </div>
                  <img src={info.img}></img>
                </div>
              );
            }
          })}
        </div>
      </div>
      {/* <div className="home bloque">
        <div className="info">
          <h1>
            <span>{data.objetivo_proyecto.title}</span>
          </h1>
          {data.objetivo_proyecto.parrafos.map((info) => (
            <p>{info}</p>
          ))}
        </div>
      </div> */}
    </>
  );
};

export default Home;
