import React, { Component } from "react";
import data from "./info";
import "./descripcion.css";

const Descripcion = (props) => {
  let resumeData = props.resumeData;
  return (
    <section id="resume">
      {data.memorias.map((info) => (
        <div className="row education">
          <h1 className="classh1">
            <span>{info.title}</span>
          </h1>
          <div className="three columns header-col"></div>

          <div className="nine columns main-col">
            {info.section.map((sect) => (
              <div className="row item">
                <div className="twelve columns">
                  <h3>{sect.subtitle}</h3>
                  {sect.parrafos.map((p, i) => (
                    <p>{`${p}`}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </section>
  );
};
export default Descripcion;
