import React, { Component } from "react";
import data from "./info";
import "./about.css";

const About = (props) => {
  let resumeData = props.resumeData;
  return (
    <>
      <section className="back-oscuro" id="about">
        <div className="row">
          <div className="three columns">
            <img className="profile-pic" src="images/profilepic.jpeg" alt="" />
          </div>

          <div className="nine columns main-col">
            <h2>Mi nombre es Vicent Silvestre Mollá.</h2>
            <p>{data.intro}</p>
          </div>
        </div>
      </section>

      <section id="about">
        <div className="row">
          {data.parrafos.map((parrafos) => (
            <p>{parrafos}</p>
          ))}
        </div>
      </section>
    </>
  );
};

export default About;
