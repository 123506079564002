import ia1 from "./media/ia1.png";
import ia2 from "./media/ia2.png";
import ib1 from "./media/ib1.png";
import ib2 from "./media/ib2.png";
import ic1 from "./media/ic1.png";

const data = {
  intro:
    "Bibliografía de las fuentes que han sido y siguen siendo mi gran inspiración.",
  libros: [
    {
      img: [ia1, ia2],
      text:
        "Rudolf Steiner en la antroposofía con su libro “Antroposofía, introducción al conocimiento del ser humano” María Thun con sus calendarios anuales “Calendario de agricultura biodinámica XXXX” y diferentes tratados.",
    },
    {
      img: [ib1, ib2],
      text:
        "Masanobu Fukuoka en su tratado de “La revolución de una brizna de paja” y “La senda natural del cultivo”.",
    },
    {
      img: [ic1],
      text:
        "Y sobre todo, un libro llamado “Tornem a Viure” de Jacques Massacrier, un ejecutivo francés que en los años 70 decidió abandonar la urbe e instalarse en el campo. Lo utilicé como libro de cabecera por lo sencillo y completo que es. En honor a todo lo que me inspiró este libro, para desarrollarme en este estilo de vida natural, quiero que el proyecto agroecológico lleve esa consigna de “VOLVER A VIVIR”, y ahora más que nunca.",
    },
  ],
};
export default data;
