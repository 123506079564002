import React, { Component } from "react";
import "./bibliografia.css";
import data from "./info";

const Bibliografia = () => {
  return (
    <>
      <div className="home bloque" style={{paddingBottom:"20px"}}>
        <div className="info cursiva">
          <p>{data.intro}</p>
        </div>
      </div>
      <div className="home bcg">
        <div className="grid-padre">
          {data.libros.map((info, i) => {
            if (i % 2 === 0) {
              return (
                <div className="grid-common biblio-grid">
                  <div className="text">{info.text}</div>
                  <div
                    className="img"
                    style={{
                      display: "grid",
                      gridGap: "5px",
                      alignItems: "center",
                      justifyItems: "center",
                      gridTemplateColumns:
                        info.img.length > 1 ? "1fr 1fr" : "150px",
                    }}
                  >
                    {info.img.map((image) => (
                      <img style={{maxHeight:"150px"}} src={image}></img>
                    ))}
                  </div>
                </div>
              );
            } else {
              return (
                <div className="grid-common biblio-grid2">
                  <div className="text2">{info.text}</div>
                  <div
                    className="img2"
                    style={{
                      display: "grid",
                      gridGap: "5px",
                      alignItems: "center",
                      justifyItems: "center",
                      gridTemplateColumns:
                        info.img.length > 1 ? "1fr 1fr" : "150px",
                    }}
                  >
                    {info.img.map((image) => (
                      <img style={{maxHeight:"150px"}} src={image}></img>
                    ))}
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
      {/*  <div className="home bloque">
        <div className="info">
          <h1>
            <span>{data.objetivo_proyecto.title}</span>
          </h1>
          {data.objetivo_proyecto.parrafos.map((info) => (
            <p>{info}</p>
          ))}
        </div>
      </div> */}
    </>
  );
};

export default Bibliografia;
