import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Portfolio from "./components/Portfolio";
import Testimonials from "./components/Testimonials";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import resumeData from "./resumeData";
import { Home, About, Descripcion, Bibliografia } from "./pages";
import { ScrollToTop, Header } from "./components";
import "./layout.css";
const App = () => {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Header resumeData={resumeData} />
        <Switch>
          <Route path="/sobre_mi">
            <About resumeData={resumeData} />
          </Route>
          <Route path="/descripcion">
            <Descripcion resumeData={resumeData} />
          </Route>
          <Route path="/bibliografia">
            <Bibliografia resumeData={resumeData} />
          </Route>
          <Route path="/contact">
            <ContactUs resumeData={resumeData} />
          </Route>
          <Route path="/portfolio">
            <Portfolio resumeData={resumeData} />
          </Route>
          <Route path="/">
            <Home resumeData={resumeData} />
          </Route>
        </Switch>
        {/* <About resumeData={resumeData} />
        <Resume resumeData={resumeData} />
        <Testimonials resumeData={resumeData} />
      <ContactUs resumeData={resumeData} /> */}
        <Footer resumeData={resumeData} />
      </Router>
    </div>
  );
};

export default App;
