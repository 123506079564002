const data = {
  intro: `Vengo de una familia de agricultores que aproximadamente por el año 1940 empezó su andadura en este medio tan duro pero a la vez tan gratificante de la agricultura de subsistencia. Trabajaban en una finca de los alrededores de Ontinyent en calidad de medieros, y en 1959 nací yo, el hijo más pequeño y único varón de una familia compuesta casi toda por mujeres (cuatro hermanas mayores y mi madre). Mis primeros años de vida fueron rodeado de animales y en un entorno muy campestre.`,
  parrafos: [
    "Hacia el año 1968, mis padres decidieron trasladarse a la ciudad, ya que en aquella época vivir en el campo suponía un inconveniente muy grande para asistir al colegio y mis hermanas mayores ya querían incorporarse a la floreciente industria textil que en aquellos años prometía un futuro esperanzador. Por ello, mi padre no tuvo más remedio que dejar la finca plantada y en plena producción, (labor que iniciaron mis abuelos antes que él), y después de más de 30 años de dedicación, las últimas palabras que mi padre recibió de los dueños de la finca, como pago y agradecimiento, fueron: “cómo te vas porque quieres no tienes derecho a nada”.",

    "Y aquí empezó una nueva etapa de nuevos retos y cultivos, pero esta vez, en sus propias tierras, fruto de las herencias familiares.",

    "Es importante resaltar que la demanda de mano de obra para la industria en aquellos momentos hizo a muchos campesinos vender sus huertas, para que la industria se fuera asentando y así ellos pudieran realizar una cómoda jornada laboral que en este caso no era de sol a sol, lo que suponía un gran alivio para muchos.",

    "Mi padre a su manera se negó a caer en las garras de la prometedora industria, ya que su vocación y relación con el medio natural, seguía muy viva. Recuerdo que a finales de los 70 yo me preguntaba cuando veía cómo iba asentándose la industria en las mejores tierras, las más fértiles las de regadío, ¿cómo retiraremos toda esa cantidad de hormigón el día que las necesitemos? Finalmente acabé incorporándome al devenir de la industria, que es donde yo veía que se cortaba el bacalao debido al poder adquisitivo que se iba generando y que te permitía comprar cosas (una moto, discos, tocadiscos, radiocasete, un coche...).",

    "En aquellos años y habiendo cumplido ya con la patria, (fue la primera vez que viajé solo, lejos de mi casa y subí en un avión). Entrados en los 80, vino mi primera gran decepción, y es que pude ver que esa rutina era para toda la vida. Por aquel entonces aún habían cosas que eran para “toda la vida”, y me dije a mi mismo: “si esto es la vida, a mí, que me borren pero ya”.",

    "Después de esto, en el mes de septiembre de 1982 me marché a vivir del campo. Sentía que debía de alguna manera, recuperar esas sensaciones que aún latían en mi interior, y compré una pequeña finca con el dinero que pude reunir de una de las primeras crisis de la industria.",

    "Mi actual pareja y madre de mis 5 hijos, Carmen, me acompañó en esta mi primer aventura. Curiosamente ella se encontraba en una situación muy parecida a la mía, y eso de ir a vivir al campo le sonó de maravilla.",

    "Allí fue donde empecé a tomar consciencia de muchas cosas, que, aunque parezca mentira, no me había enterado hasta ese momento, todo ello debido a los meticulosos cuidados a los que me habían sometido aquellas cinco mujeres. La ropa se ensuciaba y había que ir a lavarla al rio y todo un sinfín de labores que tuve que ir aprendiendo, para administrar y poner en orden mi vida.",

    "Me interese mucho por la antroposofía, y sobre todo, su aplicación en la agricultura biodinámica. Y ahí, empezó, mi carrera personal de aprendizaje de las cosas que me parecían útiles y trascendentes. También leí todo lo que pude encontrar sobre medicina natural, diferentes tratados sobre higienismo, pero, sobre todo, cómo los diferentes elementos naturales, agua, tierra, sol y aire, podían interactuar de forma eficiente a recuperar tu salud.",

    "Apenas había pasado un mes, la naturaleza nos recibió con vehemencia. El día 20 de octubre nos sacudió con un auténtico diluvio, los días se sucedían lentamente, aquello parecía que no fuera a parar nunca, llegando a saturar tierras y ríos de una manera más que sorprendente, pero como siempre, la tempestad pasó y llegó la calma. El agua brotaba por todas partes, caminábamos descalzos para aprovechar el regalo que se nos había brindado, vivíamos a todo lujo, sin agua corriente y sin electricidad, pero con un pequeño riachuelo muy cerca y el sol calentando todo el día. Disponíamos de un pequeño refugio que, paso a paso, fuimos habilitando y proveyendo de los mínimos enseres que precisábamos para una vida sencilla. Éramos jóvenes y teníamos muchísimos proyectos que queríamos realizar, y en aquellos momentos no nos importaba tanto, no tener una cómoda casa, solo queríamos estar el mayor tiempo posible al aire libre, disfrutando y construyendo, unas mínimas infraestructuras que nos permitieran acercarnos cada vez más, al ansiado autoabastecimiento. Construimos un horno de leña, una huerta para el suministro de verduras y hortalizas, también nos ocupamos de los cuidados de mantenimiento de los diferentes árboles frutales. La pequeña finca tenía una superficie de 2,5 hectáreas plantada de frutales en producción. Construimos un pequeño corral para dos cabras que nuestro vecino pastor nos cedió por tiempo indefinido, y con la leche aprendimos a hacer queso fresco y kéfir para los suculentos desayunos.",

    "Un día mientras preparábamos los bancales profundos al lado del rio, nuestro vecino pastor, como cada día, paseaba su pequeño rebaño de cabras y se acercó a saludarnos. Me dijo que se había enterado, que en un pueblo no muy lejos de allí, vendían un caballo, que, aunque era de raza fina sabía labrar las tierras. Cuando yo escuché aquello, algo dentro de mi floreció. Ya no dormí ni una sola noche sin fantasear en cómo sería mi vida allí con un caballo. Nos informamos bien de cuál era el precio, y quedamos con los dueños, que lo recogeríamos en unos días. Preparamos un establo rápidamente junto al de las cabras que ya teníamos, y por fin llegó el día soñado.",

    "Salimos de buena mañana y mi vecino recogió a su cuñado que era un experto en caballos y mulos y nos ayudaría a ver el estado del animal. Llegamos, se procedió al examen y rápidamente al regateo. Cerramos el trato y empezó la odisea. El viaje iba a ser a pie, disfrutando del paisaje, unos 30 km aproximadamente, me explicaron la ruta a seguir hasta llegar a una montaña que debería cruzar para cambiar de comarca y seguir otros 15 km más hasta llegar a la finca, lo veía chupado).",

    "Seguramente no escuché y tampoco pensé en que no tenía ninguna experiencia con animales de ese tamaño, pero parece que por como acontecieron las cosas debía llevarlo de serie, cogí mi bolsa con algo de comida y agua, cogí el caballo y me puse en marcha. Aún no habíamos recorrido ni un par de kilómetros, el caballo empezaba a pararse. Yo amigablemente le invitaba a seguir contándole todo lo que nos esperaba juntos, y funcionó mientras funcionó. Me vino a la mente, lo que pensé cuando lo vi por primera vez, en ese establo tan pequeño donde estaba, posiblemente no lo habrían sacado a pasear cada día, ya que sus dueños eran ya mayores. Entonces como podréis imaginar tuve que ingeniármelas y probar un montón de estrategias, empecé dándole una manzana que llevaba. Después el bocadillo y al final ¡todo lo que llevaba en la bolsa!",

    "El peor momento fue después de cruzar las montañas y descender a una carretera donde tuve que andar unos kilómetros sobre ella, se asustaba muchísimo cuando pasaba algún vehículo. Pronto llegamos a una vereda que desembocaba en otra vereda y así paso a paso íbamos recorriendo el camino. Y por fin, después de pelear todo el día con mi nuevo compañero, sobre las 8 de la tarde, llegamos. Me temblaban los brazos y las piernas de la tensión y el esfuerzo realizados.",

    "Lo dejé en su nueva cuadra con comida y agua abundante, y me metí en elrio para hidratarme y descansar.",

    "Se adaptó perfectamente al nuevo entorno, cada día, después de comer, sacaba a las cabras y el caballo a pastar libremente, ya que junto a nuestros terrenos se encontraba una inmensidad de tierra libre, perteneciente a las confluencias de un pantano que se encontraba a unos kilómetros de distancia. Allí fue, donde empezó a renacer Lucero, como yo le llamada. Un árabe-español con una fuerza y un brío que me alucinaban. Trotaba y corría en libertad como si nunca antes lo hubiera hecho.",

    "Cada día, paso a paso, fue recuperando su tono y pronto lo acompañé en esas carreras que parecían interminables. Lo montaba a pelo, (sin montura, ni estribos), y parecía que lo hubiera hecho toda la vida, nos fundíamos en uno, era una sensación indescriptible, me sentía como un indio americano galopando en libertad.",

    "Cuando ya estaba bastante aclimatado el animal, le pedí a mi vecino que me enseñara a arar con el caballo. Como estaba en desuso el arte de arar, pronto pude conseguir fácilmente, un arado y todos los complementos para el enganche. Cuando lo tuve todo preparado, vino otra vez el cuñado de mi vecino, que no solamente era un experto de los animales sino de sus labores también, empezó todo un ritual de piezas que iba colocándole al animal.",

    "Después continuó con el enganche del arado, yo no le perdía ojo a todo el proceso. Cuando terminó y después de comprobarlo todo, encaró al animal con el arado a un campo que estaba sin arar. Yo me pegué a ellos y fuimos abriendo un surco de parte a parte del campo. Cuando llegamos al final, le dio la vuelta al animal y lo colocó en el surco siguiente. Paró y me dijo: “así se ara, cógelo y continua”. Se marchó y nunca más necesité de su ayuda otra vez. Lo aprendí todo en un instante, y una vez más, me di cuenta de que lo debía llevar en mi ADN, de serie. Y así debió de ser, ya que tenía muy vivo en mi interior los recuerdos de mi padre labrando las tierras cuando yo aún era solo un niño.",

    "Con el paso de los días, fui labrando y tomando soltura. Aunque tenía un tractor mediano, con el caballo realizaba las tareas más delicadas ya que me permitía acceder más fácilmente y la labor era de mucha calidad.",

    "Un tiempo después, hacía el verano siguiente, en las tierras contiguas que formaban parte del pantano, cuando llegaban esas fechas las tierras más altas quedaban al descubierto y eran tierras muy fértiles en las que se podía sembrar. Con el caballo podía acceder fácilmente y pronto empecé a sembrarle diferentes tipos de forraje, avena, maíz, remolachas forrajeras que le encantaban, y también otros cultivos para la casa. Cuando estos ya estaban listos, los segaba y preparaba para transportarlos al estilo local con el caballo, nos compenetrábamos muy bien, aunque lo que a él más le gustaba eran las tardes donde se explayaba a su aire todo lo que quería.",

    "En el verano del 84 nació nuestra primera hija Celeste, que fue recibida con honores de verano a primeras horas de un 15 de Julio en la pequeña casita que nos acogía. Todo fue según el guion de novatos que teníamos preparado. La cogí, se la entregué a su madre, cortamos su cordón umbilical y empezamos a disfrutar de las sinfonías de la naturaleza juntos.",

    "En el 87 iniciamos un nuevo proyecto, la recuperación de un pueblo abandonado junto con un grupo de guerreros amantes de la naturaleza como nosotros, de diferentes partes del mundo. Formamos un equipo de 12 adultos y 14 niños. Reconstruimos diferentes casas y pusimos las huertas en producción rápidamente, ya que todos los integrantes veníamos con años de experiencias personales de autoabastecimiento. Lo llevábamos todo preparado de antemano, planteles variados que íbamos a necesitar, los animales, caballos, cabras y otros domésticos. Fabricábamos queso, pan y diferentes tipos de repostería. Ropa cómoda de algodón y algunas cosas más que íbamos vendiendo en las primeras ferias alternativas. Pero pronto se cerró nuestro ciclo allí y decidimos volver al pueblo que nos vio partir, Ontinyent.",

    "Por el año 89 ya tenía en marcha un nuevo proyecto, esta vez de jardinería y producción de plantas medicinales y árboles autóctonos. En enero de 1990 nació nuestra segunda hija, Roser. Tres años después, nació el primer varón, Vicent Antoni, y un año y medio después de él Ferran. Finalmente, en el 2002 nació Joan, y como era tradición ya en nosotros, les dimos la bienvenida uno a uno, nosotros dos con toda la calma y tranquilidad que se podía, en alguna ocasión, algún hermano se apuntó a la fiesta.",

    "Volviendo un poco atrás, en el 97 me di cuenta, que necesitaba pasar más tiempo con mi familia, mis hijos lo necesitaban y yo también. Sin darme cuenta me había convertido en un proveedor de mi familia, en este caso el principal y decidí parar, después de un breve período de reflexión y enfoque, se me presentó la oportunidad de realizar, un retiro de meditación de treinta días, en un monasterio budista en Katmandú, al pie de los Himalayas, después concluiría, con un pequeño tour de unos veinte días más por India, el viaje en sí me ayudó muchísimo a reenfocarme, y ver con claridad el nuevo proyecto familiar. El proyecto estaba enfocado en crear sistemas para el descanso y sus complementos, todo ecológico y natural. Ofrecíamos todo lo necesario para un apropiado descanso, camas de madera ecológicas, colchones de látex natural, edredones de lana merina, sabanas de algodón orgánico y cualquier complemento que se precisara para el descanso.",

    "Empecé presentándolos en pequeñas ferias de productos naturales y más tarde en el circuito de ferias de Biocultura, además de otras a nivel nacional relacionadas con este movimiento que empezaba a pisar fuerte. El nombre comercial que emplee fue “Artesanía Silvestre” junto con el eslogan de “ergonomía en el descanso”.",

    "En el 2009 me sorprendió, de forma contundente, la paralización que se produjo en la economía, resistí como pude hasta finales del 2012 cuando ya se desplomó totalmente “el castillo de naipes” en el que estaba inmerso. Pero como es mi lema: “no pasa nada, unos años de recarga”, y, en la primavera del 2016 empecé un nuevo proyecto, en este caso orientado a la la prevención de la salud, todo ello fue posible y avalado por la larga experiencia y conocimientos de la salud y el bienestar que ya atesoraba.",

    "En el 2019 cerré un nuevo ciclo empresarial, y en este tan “especial” 2020, he decido continuar y terminar lo que empecé ya hace casi 40 años.",

    "Me parece que, ahora más que nunca, este proyecto que voy a implementar puede servir como modelo duplicable para cualquier persona que desee reorientar su vida a ¡¡¡vivir de forma natural del campo!!! “Esta será mi contribución en estos momentos de cambios tan profundos",
  ],
};
export default data;
