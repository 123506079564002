import React, { Component } from "react";
export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section className="back-oscuro" id="about">
        <div className="row">
          <div className="three columns">
            <img className="profile-pic" src="images/profilepic.jpeg" alt="" />
          </div>

          <div className="nine columns main-col">
            <h2>Datos de contacto</h2>
            <p
              style={{
                display: "grid",
                gridTemplateColumns: "20px 1fr",
                gridGap: "20px",
                alignItems: "center",
                /* justifyItems: "center", */
              }}
            >
              <i className="fa fa-phone" />
              <span>+34 658 981 085</span>
              <i className="fa fa-envelope " />
              <span>proyectovolveravivir2020@gmail.com</span>
            </p>
          </div>
        </div>
      </section>
    );
  }
}
